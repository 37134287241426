<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    OtherLoading
  },
  data() {
    return {
      lineProfile: {},
      isLoading: true,
      filter: {
        SocialProvider: "line",
        SocialId: ""
      }
    };
  },
  beforeCreate: async function () {
    this.$liff.init({ liffId: this.$liff_ID_Login });
  },
  async created() {
   
  },
  methods: {
    async LoginExternal() {
      this.filter.SocialId = this.lineProfile.userId;
      await this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/user/LoginExternal`,
          this.filter
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 0) {
            this.$cookies.remove("hourglass_register_success");
            this.$cookies.remove("hourglass_token");
            this.$router.push("/validatetelephone");
          } else {
            this.$cookies.set("hourglass_token", data.detail.token);
            this.$router.push("/profile");
          }
        });
    }
  }
};
</script>
